import {Typography, Container} from '@mui/material';

function Game() {
  return (
    <Container>
      <Typography variant="h1">Game Page</Typography>
    </Container>
  );
}

export default Game;